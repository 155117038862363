import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ControlBase } from '../control-base.directive';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule],
})
export class InputComponent extends ControlBase implements OnChanges {
  @Input() public type: string = 'text';
  @Input() public hideSymbols: boolean = false;

  @ViewChild('input') private input: ElementRef;

  public symbolsHidden: boolean;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideSymbols?.firstChange) {
      this.symbolsHidden = changes.hideSymbols.currentValue;
    }
  }

  public toggleShowSymbols(): void {
    this.symbolsHidden = !this.symbolsHidden;
  }

  public focus(): void {
    this.input.nativeElement.focus();
  }
}
