<mat-form-field class="wrapper">
  @if (labelText) {
    <mat-label>{{ labelText }}</mat-label>
  }
  <input
    #input
    matInput
    [formControl]="control"
    [placeholder]="placeholderText"
    [type]="symbolsHidden ? 'password' : type"
    (blur)="blur($event)"
  />
  @if (hideSymbols) {
    <button matSuffix type="button" (click)="toggleShowSymbols()">
      <mat-icon>
        {{ symbolsHidden ? 'visibility_off' : 'visibility' }}
      </mat-icon>
    </button>
  }

  @if (control.invalid && control.touched) {
    <mat-error>{{ errorText }}</mat-error>
  }
</mat-form-field>
